import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { ResetPasswordForm } from 'layouts';
import { BASE_URL } from 'config';

// eslint-disable-next-line
const ResetPasswordPage = () => (
  <Layout page="user/reset-password">
    <Helmet
      title="Reset Your Password | Haka Tours, New Zealand"
      meta={[
        {
          name: 'description', content: 'Need to reset your password? No problem! Reset your Haka Tours password here. ',
        },
        { name: 'og:title', content: 'Reset Your Password | Haka Tours, New Zealand' },
        {
          name: 'og:description', content: 'Need to reset your password? No problem! Reset your Haka Tours password here. ',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/user/reset-password/` }]}
    />

    <ResetPasswordForm />
  </Layout>
);

export default ResetPasswordPage;
